@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Libre+Franklin:ital,wght@0,100..900;1,100..900&family=Signika+Negative:wght@300..700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --main-color: #7963f0;
  --light-color: #faca15;
  --dark-color: #8b5cf6;
  --focus-color: #ff9d03;
  --blue-color: #1877f2;
  --icon-color: rgba(255, 158, 12, 0.2);
  --gray-color: #828282;
  --error-color: #d3232f;
  --light-gray-color: #f6f6f7;
  --mid-gray-color: #e3e3e3;
  --dashboard-gray-color: rgb(99, 115, 129);
  --dashboard-heading: #793708;
  --dashboard-p: #b57c4f;
  --success-color: rgb(17, 141, 87);
  --success-background: rgba(34, 197, 94, 0.16);
  --danger-color: rgb(183, 29, 24);
  --danger-background: rgba(255, 86, 48, 0.16);
  --warning-color: rgb(183, 110, 0);
  --warning-background: rgba(255, 171, 0, 0.16);
  --dark-text-color: #000000;
  --light-text-color: #ffffff;
  --swiper-theme-color: #ff9900f3 !important;
  /* form styling */
  --input-radius: 6px;
  --input-bg-faded: #fbfcfe;
  /* --input-shadow: 0px 1px 2px 0px var(--mid-gray-color); */
  --input-shadow: rgba(17, 12, 46, 0.15) 0px 20px 40px 0px;
  /* --dark: #171717; */
  --dark: #070707;
  --dark-light: #191919;
  --light: #dbdbdb;
  --gray: #424242;
  --butn-border-radius: 6px;
  --modal-background: #3a373b;
}

* {
  -webkit-box-sizing: border-box;
  zoom: 1;
  @apply box-border;
  font-optical-sizing: auto !important;
}

html {
  scroll-padding-top: var(--scroll-padding, 100);
  @apply scroll-smooth;
  @apply bg-[--dark];
}

*,
body,
html {
  font-family: "Signika Negative", sans-serif !important;
}

h3.footer-head {
  @apply mb-2 sm:mb-6 p1 font-normal text-[--gray-color] uppercase tracking-wide dark:text-[--gray-color];
}

.social-icon {
  @apply flex justify-start sm:justify-end items-start gap-3 m-0;
}

.social-icon a {
  @apply text-black dark:text-black px-1.5 border border-[--light] shadow bg-white rounded-md;
}

.footer-list {
  @apply text-black font-medium space-y-1;
}

.footer-list li .footer-list-item {
  @apply font-medium p0 cursor-pointer;
}

.footer-item {
  @apply flex flex-wrap lg:justify-center items-start pr-4;
}

.footer-item.links {
  @apply w-full sm:w-1/3 lg:w-[15%] sm:order-1 lg:order-none pt-8 lg:pt-0;
}

.footer-item.about {
  @apply justify-start w-full sm:w-1/2 lg:w-[28%] xl:w-[30%];
}

.footer-item.social-menus {
  @apply sm:justify-end pr-0 w-full sm:w-1/2 lg:w-[27%] xl:w-1/4 pt-8 sm:pt-0;
}

.footer-item .child-item {
  @apply max-w-fit;
}

.footer-item.links.helpfulLinks {
  @apply sm:justify-end lg:justify-center;
}

.footer-item.links.seller-menus {
  @apply sm:justify-center;
}

.noTextBorder {
  text-shadow: none;
}

.dropzonFileUploader {
  @apply flex flex-col items-center justify-center w-full h-36 sm:h-48 md:h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-black dark:bg-[--dark] hover:bg-gray-100 dark:border-[--gray] darkShadow;
}

.dropzonFileUploaderIcon {
  @apply w-8 h-8 mb-4 text-gray-500 dark:text-[--light];
}

.cardDetailsLabelStyle {
  @apply rounded-md min-w-24 sm:min-w-28 lg:min-w-32 py-1 sm:py-1.5 flex flex-col gap-0 justify-center items-center;
}

.cardDetailsLabelStyle p {
  @apply font-semibold p0;
}

.cardDetailsLabelStyle span {
  @apply font-normal p2;
}

.cardDetailsLabelStyle p,
.cardDetailsLabelStyle span {
  line-height: 1.1;
  @apply text-black dark:text-black;
}

.cardDetailsLabelStyle.warZone p,
.cardDetailsLabelStyle.warZone span {
  @apply text-white dark:text-white;
}

/* bread crumbs styling */
.breadCrumbsMianTitle {
  @apply p00 leading-6 font-medium tracking-wide text-[--text-dark-color] dark:text-white;
}

/* schedule dates and times */
.clayEffect {
  @apply shadow-customLight dark:shadow-customDark dark:border dark:border-[--gray] rounded-[--butn-border-radius];
}

.textBorder {
  text-shadow:
    -1px -1px 0 var(--dark-text-color),
    1px -1px 0 var(--dark-text-color),
    -1px 1px 0 var(--dark-text-color),
    1px 1px 0 var(--dark-text-color);
  /* dark text color border */
  letter-spacing: 2px;
}

.textBorderPrimary {
  text-shadow:
    -1px -1px 0 var(--main-color),
    1px -1px 0 var(--main-color),
    -1px 1px 0 var(--main-color),
    1px 1px 0 var(--main-color);
  /* main color border */
  letter-spacing: 2px;
}

.textBorderSecondary {
  text-shadow:
    -1px -1px 0 var(--light-color),
    1px -1px 0 var(--light-color),
    -1px 1px 0 var(--light-color),
    1px 1px 0 var(--light-color);
  /* light color border */
  letter-spacing: 2px;
}

.searchBox {
  @apply flex justify-center items-center w-full font-semibold text-gray-900 border-2 border-gray-200 rounded-lg bg-gray-50 p1 dark:bg-[--dark] dark:text-white dark:border-[--gray] px-2.5 md:px-4 py-2 md:py-3.5;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 12px 20px 0px;
}

input::placeholder {
  @apply dark:text-white;
}

.htmlSelector {
  @apply bg-gray-50 border border-gray-300 text-black rounded-lg block w-full p-2.5 dark:bg-[--dark] dark:border-[--gray] dark:text-white;
}

.htmlSelector,
.htmlSelector option {
  font-size: 1rem !important;
}

.inputLabel {
  @apply dark:text-[--light];
}

.menusDarkStyling {
  @apply dark:border dark:border-[--gray] dark:bg-[--dark];
}

input,
textarea,
select {
  @apply text-[16px];
}

.peo {
  padding-right: 0 !important;
}

input,
textarea,
select {
  @apply touch-manipulation;
}

.modalForm {
  @apply overflow-y-auto overflow-x-hidden mt-2;
}

.hovershadow:hover {
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px !important;
}

.cardHoverShadow {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.cardHoverShadow:hover {
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.1);
}

#featured-slider .control-dots .dot {
  @apply bg-white border border-white;
}

#featured-slider .control-dots .dot.selected {
  @apply bg-[--main-color] border-none;
}

.listingCard {
  @apply relative w-full md:max-w-[500px] flex flex-col text-gray-700 bg-white bg-clip-border rounded-xl border border-gray-200 px-4 py-6 gap-4 h-full dark:bg-[--dark] dark:border-[--gray] drop-shadow-lg hover:drop-shadow-hoverLight darkShadow hoverDarkShadow mx-auto;
}

:is(.dark .darkBlack){
  color: black !important;
}

:is(.dark .darkBlack2){
  color: var(--dark) !important;
}

.no_reserve_label {
  @apply min-h-6;
}

.no_reserve_label.hide {
  @apply invisible;
}

.no_reserve_label .no_reserve_text {
  @apply p1 text-[--main-color] dark:text-[--main-color] w-fit h-fit mb-2 font-bold flex justify-start items-baseline gap-1;
}

.no_reserve_label .no_reserve_text::before {
  content: "\2022";
}

.headerTitle {
  @apply text-white text-center dark:text-white head font-normal flex-wrap px-5;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: "scale(0.5)";
  }

  100% {
    opacity: 1;
    transform: "scale(1)";
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: "scale(1)";
  }

  100% {
    opacity: 0;
    transform: "scale(0.9)";
  }
}

.mainContainer {
  @apply flex flex-col h-full w-full xl:container mx-auto;
}

.darkShadow {
  /* @apply dark:drop-shadow-[0_0_4px_rgba(255,255,255,.1)]; */
}

.hoverDarkShadow {
  @apply dark:hover:drop-shadow-[0_0_18px_rgba(255,255,255,.15)];
}

.hrDarkMode {
  @apply dark:border-[--gray];
}

.head1,
.head2,
.head3 {
  @apply tracking-wider;
}

.facts_dt,
.facts_dd {
  @apply w-full px-2.5 py-2 border dark:border-[--gray] cellTextCenter;
}

.facts_dl,
.facts_dt,
.facts_dd {
  @apply border-collapse;
}

.facts_dl {
  @apply w-full md:w-1/2 flex;
}

.facts_dt {
  @apply bg-gray-100 dark:bg-black sm:w-1/3 font-bold dark:text-white;
}

.facts_dd {
  @apply overflow-wrap-anywhere sm:w-2/3;
}

.head4,
.head5,
.head6,
.head7,
.head8,
.head9,
.head {
  @apply tracking-wide;
}

.head1,
.head2,
.head3,
.head4,
.head5,
.head6,
.head7,
.head8,
.head9,
.head,
h1,
h2,
h3,
h4,
h5,
h6,
h1 p,
h2 p,
h3 p,
h4 p,
h5 p,
h6 p,
h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span,
h1.p0,
h1.p00,
h1.p000,
h1.p1,
h1.p2,
h1.p3,
h2.p0,
h2.p00,
h2.p000,
h2.p1,
h2.p2,
h2.p3,
h3.p0,
h3.p00,
h3.p000,
h3.p1,
h3.p2,
h3.p3,
h4.p0,
h4.p00,
h4.p000,
h4.p1,
h4.p2,
h4.p3,
h5.p0,
h5.p00,
h5.p000,
h5.p1,
h5.p2,
h5.p3,
h6.p0,
h6.p00,
h6.p000,
h6.p1,
h6.p2,
h6.p3 {
  @apply dark:text-white;
}

.head1,
.head2 {
  @apply font-bold;
}

.head3,
.head4 {
  @apply font-semibold;
}

.head5,
.head6,
.head7,
.head8,
.head9,
.head {
  @apply font-medium;
}

.head1 {
  @apply 2xl:text-[56px] lg:text-[52px] md:text-5xl text-4xl;
}

.head2 {
  @apply 2xl:text-[52px] lg:text-[50px] md:text-[46px] text-[34px];
}

.head3 {
  @apply 2xl:text-5xl lg:text-[44px] md:text-[42px] text-[42px];
}

.head4 {
  @apply 2xl:text-[42px] lg:text-[40px] md:text-4xl text-3xl;
}

.head5 {
  @apply 2xl:text-[40px] lg:text-4xl md:text-[32px] text-[28px];
}

.head6 {
  @apply 2xl:text-4xl lg:text-[32px] md:text-[30px] text-[26px];
}

.head7 {
  @apply md:text-[26px] text-2xl;
}

.head7,
.head8 {
  @apply 2xl:text-[32px] lg:text-3xl text-[22px];
}

.head8,
.head9 {
  @apply md:text-2xl;
}

.head9 {
  @apply 2xl:text-3xl lg:text-[28px] text-xl;
}

.head {
  @apply 2xl:text-3xl sm:text-2xl text-xl;
}

.dot {
  @apply w-1 h-1;
}

.cardMainTitle {
  @apply truncate pe-8 p00 font-semibold;
}

.verified {
  @apply fill-[--light-color] stroke-[--dark-text-color];
}

.cardSubTitle {
  @apply text-gray-500 p1 truncate flex justify-start items-center gap-2 capitalize;
}
.listSubTitle {
  @apply p1;
}
:is(.dark .cardSubTitle),
:is(.dark .listSubTitle) {
  color: var(--light) !important;
}

.butnText {
  @apply font-medium text-base;
}

.p000 {
  @apply sm:text-2xl text-2xl;
}

.p00 {
  @apply sm:text-xl text-[1rem];
}

.p0 {
  @apply text-lg;
}

.p1 {
  @apply text-base;
}

.p2 {
  @apply text-sm;
}

.p3 {
  @apply text-xs;
}

.p000,
.p00,
.p0,
.p1,
.p2,
.p3,
p {
  @apply dark:text-[--light];
}

#flow_diagram .flow_item {
  @apply lg:w-1/3 lg:flex lg:flex-col lg:items-center;
}

.radioInput:focus,
.checkedInput:checked {
  box-shadow:
    0 0 0 2px #fff,
    0 0 0 4px var(--main-color);
}

.navButn {
  @apply p1 px-2.5 2xl:px-5 py-2 focus:outline-none rounded-[--butn-border-radius] text-center w-fit items-center justify-center sm:flex lg:block text-white dark:text-white;
}

.navButn.primary {
  @apply bg-[var(--gray)] hover:bg-[var(--gray)];
}

.navButn.primary-outline {
  @apply border border-[var(--gray)];
}

.navButn.secondary {
  @apply bg-[--main-color] hover:bg-[--main-color];
}

.navButn.themeToggler {
  @apply bg-[--light-color];
}

/* scrollbar style starts */
/* width */
::-webkit-scrollbar {
  @apply w-[6px] h-[6px];
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(51, 51, 51, 0.445);
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-gradient-to-b from-[var(--main-color)] via-[var(--light-color)] to-[var(--main-color)] rounded-[20px] hover:from-[var(--light-color)] hover:via-[var(--main-color)] hover:to-[var(--light-color)];
}

::-webkit-scrollbar-thumb:horizontal {
  @apply bg-gradient-to-r;
}

/* scrollbar style end */

/* utilitly classes start here */
.settingBadge {
  @apply bg-[var(--dark-color)];
}

.inputLabel {
  @apply text-[var(--dark-text-color)];
}

.darkText {
  @apply text-[var(--dark-text-color)];
}

.shadow2 {
  -webkit-box-shadow:
    inset 0px 6px 12px -4px rgba(0, 0, 0, 0.1),
    0px 6px 10px -3px rgba(0, 0, 0, 0.1) !important;
  box-shadow:
    inset 0px 6px 12px -4px rgba(0, 0, 0, 0.1),
    0px 6px 10px -3px rgba(0, 0, 0, 0.1) !important;
}

.accordionShadow {
  -webkit-box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12),
    inset 0px 4px 8px -3px rgba(0, 0, 0, 0.1),
    0px 6px 8px -3px rgba(0, 0, 0, 0.1) !important;
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12),
    inset 0px 4px 8px -3px rgba(0, 0, 0, 0.1),
    0px 6px 8px -3px rgba(0, 0, 0, 0.1) !important;
}

.info_message {
  @apply py-8 text-left px-4 p1 w-full;
}

#swal2-title {
  @apply head;
}

#swal2-html-container {
  @apply p1;
}

[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  -webkit-box-shadow: none !important;
  outline: none !important;
  border: var(--main-color) !important;
  box-shadow: none !important;
}

.shadow3 {
  -webkit-box-shadow: inset 0px 6px 12px -4px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0px 6px 12px -4px rgba(0, 0, 0, 0.1);
}

:is(.dark .shadow3) {
  -webkit-box-shadow: inset 0px 6px 12px -4px rgba(255, 255, 255, 0.2); /* Lighter shadow for dark mode */
  box-shadow: inset 0px 6px 12px -4px rgba(255, 255, 255, 0.2);
}

.faqTrunc {
  @apply relative overflow-hidden text-ellipsis max-h-4;
}

.shadow4 {
  -webkit-box-shadow: 0px 6px 10px -3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 10px -3px rgba(0, 0, 0, 0.1);
}

.animatation-spiner {
  -webkit-animation: spin 6s linear infinite;
  animation: spin 6s linear infinite;
}

.notificationBadge {
  @apply bg-[var(--dark-color)] text-white;
  font-size: 10px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text !important;
  -webkit-transition: background-color 5000s ease-in-out 0s !important;
  transition: background-color 5000s ease-in-out 0s !important;
  -webkit-box-shadow: inset 0 0 20px 20px transparent !important;
  box-shadow: inset 0 0 20px 20px transparent !important;
}

.ScrollStyle::-webkit-scrollbar {
  @apply w-[5px];
}

.ScrollStyle::-webkit-scrollbar-track {
  @apply bg-[var(--light-gray-color)] rounded-[0.5rem];
}

.ScrollStyle::-webkit-scrollbar-thumb {
  @apply bg-[var(--gray-color)] rounded-[0.5rem];
}

/* width */
.muiTableContainer::-webkit-scrollbar {
  @apply h-[10px];
}

/* Track */
.muiTableContainer::-webkit-scrollbar-track {
  @apply bg-transparent;
}

/* Handle */
.muiTableContainer::-webkit-scrollbar-thumb {
  @apply bg-[#cdcdce];
}

.scroll-none::-webkit-scrollbar {
  @apply hidden;
}

/* width */
.overflowScrollY::-webkit-scrollbar {
  @apply w-[10px];
}

/* Track */
.overflowScrollY::-webkit-scrollbar-track {
  @apply bg-transparent;
}

/* Handle */
.overflowScrollY::-webkit-scrollbar-thumb {
  @apply bg-[#cdcdce] rounded-[2rem];
}

.errorColor {
  @apply text-[var(--error-color)] dark:text-[var(--error-color)];
  text-shadow: 0px 0px 1px rgba(255, 255, 255, 0.25);
}

.bgColor,
.hoverBgColor:hover {
  background-color: var(--main-color) !important;
}

.decorationColor {
  -webkit-text-decoration-color: var(--dark-color);
  @apply decoration-[var(--dark-color)];
}

.bgIconColor {
  background-color: var(--icon-color) !important;
}

.iconColor {
  @apply text-[var(--icon-color)];
}

.focusBgColor:focus,
.activeBgColor:active {
  @apply bg-[var(--focus-color)];
}

.textColor,
.hoverTextColor:hover {
  @apply text-[var(--main-color)];
}

.textGray,
.hoverTextGray:hover {
  @apply text-[var(--gray-color)];
}

.bgGray,
.hoverBgGray:hover {
  @apply bg-[var(--gray-color)];
}

.focusTextColor:focus,
.activeTextColor:active {
  @apply text-[var(--focus-color)];
}

.borderColor,
.hoverBorderColor:hover {
  @apply border-[var(--main-color)];
}

.focusBorderColor:focus,
.activeBorderColor:active {
  @apply border-[var(--focus-color)];
}

/* utilitly classes ends here */

/* tailwind special classes starts here */
.InputFieldSetting {
  @apply flex flex-col w-full space-y-1 gap-0.5;
}

.search-btn {
  @apply px-5 py-2 rounded-[20px] outline outline-1 outline-[var(--main-color)] bg-[var(--light-color)];
}

.tailSelector,
.transSelector {
  @apply block bg-white border text-sm font-semibold border-gray-400 hover:border-gray-500 px-2 py-2 pr-0 rounded shadow leading-tight focus:outline-none;
}

.transSelector {
  @apply md:w-full;
}

.filterStyled {
  @apply flex text-gray-600 font-medium hover:text-gray-800 overflow-hidden cursor-pointer;
}

.filterStyled.active {
  @apply text-gray-900 border-b-2 border-black;
}

.auction-card {
  @apply h-full border-gray-200 border-opacity-60 rounded-sm overflow-hidden;
}

.auction-card .auction-top-img {
  @apply w-full shadow-sm rounded cursor-pointer h-auto object-fill;
}

.navMenuLink {
  @apply text-[--gray];
}

.navSettingLink,
.navMenuLink {
  @apply block truncate lg:max-w-40;
}

.navSettingLink,
.navMenuLink {
  @apply p0 font-medium py-1 px-1 rounded lg:hover:bg-transparent lg:hover:text-[var(--main-color)] lg:p-0 cursor-pointer dark:text-white tracking-wide;
}

.navMenuLink.dropdown {
  @apply text-black lg:hover:text-[var(--main-color)] dark:text-white;
}

.activeMenu {
  color: var(--main-color) !important;
}

.navSettingLink:hover,
.navMenuLink:hover {
  @apply noTextBorder;
}

.menuList {
  @apply px-0.5 py-1 list-none;
}

.navSettingLink {
  @apply text-black;
}

.dhrOnhover hr {
  @apply hidden;
}

.dhrOnhover:hover hr {
  @apply block;
}

.auction-card .auction-item-title {
  @apply p1 font-bold text-gray-900 leading-relaxed;
}

.auction-card .auction-item-description {
  @apply leading-relaxed p1 text-gray-900 dark:text-white;
}

.auction-card .auction-item-description .desButn {
  @apply bg-[var(--main-color)] text-white text-xs font-bold py-0.5 px-2 mr-2 rounded-lg;
}

.auction-card .auction-card-address {
  @apply leading-relaxed text-sm font-normal text-gray-500;
}

.list-card {
  @apply flex w-full h-full lg:h-48 md:h-36 relative py-0.5;
}

.list-card .first-half-div {
  @apply h-full w-1/2 p-1 md:p-1;
}

.list-card .second-half-div {
  @apply flex-col;
}

.list-card .second-half-div .second-half-item {
  @apply h-1/2 p-1 md:p-1;
}

.list-card .list-img {
  @apply block h-full w-full object-cover object-center;
}

.list-card-heading {
  @apply hover:underline text-gray-900 text-xl font-bold;
}

.list-card-description {
  @apply p1 font-normal text-gray-900;
}

.list-card-description .listTypeBtn {
  @apply bg-[var(--main-color)] text-white text-xs font-bold py-0.5 px-2 mr-2 rounded-lg;
}

.list-card .listAddress {
  @apply leading-relaxed mb-2 text-lg font-normal text-gray-500;
}

.list-card .listKind {
  @apply bg-[var(--main-color)] text-white absolute bottom-3 left-3 rounded-lg px-2 py-1;
}

.recent-sale-cards {
  @apply grid lg:grid-cols-4 grid-cols-2 p-4 gap-4;
}

.recent-sale-cards .SoldCardItem {
  @apply h-full border-gray-200 border-opacity-60 rounded-sm overflow-hidden;
}

.SoldCardItem .cardImg {
  @apply lg:h-44 md:h-36 w-full object-cover object-center rounded drop-shadow-sm shadow-sm cursor-pointer;
}

.SoldCardItem .soldCarLabel {
  @apply bg-[var(--main-color)] text-white rounded-lg px-1 mt-2 drop-shadow-sm;
}

.soldCarLabel .soldCarLabelItem {
  @apply text-white items-center leading-none text-sm py-1.5 px-1.5 border-gray-200;
}

.SoldCardItem .soldedTitle {
  @apply mb-3 leading-tight font-bold capitalize text-xl cursor-pointer no-underline;
}

.CarStep {
  @apply bg-[var(--light-gray-color)] mb-8 py-6 my-3 w-full rounded-md pl-3 lg:px-6 px-2 dark:bg-transparent dark:border dark:border-[--gray] darkShadow;
}

.leftStepStyle {
  /* @apply w-2.5 rounded-r-md my-1.5 bg-[var(--main-color)] hidden; */
  @apply w-2.5 rounded-r-md my-1.5 bg-[var(--main-color)] hidden;
}

.whatsCarBidIconStyle {
  @apply w-14 h-14 p-1 mr-2 bg-[var(--light-color)] rounded text-black;
}

.whatsCardBidItem {
  @apply grid grid-cols-1 lg:grid-cols-2 w-full lg:gap-x-5 gap-y-14;
}

.whatsCardBidItem .item {
  @apply flex flex-col items-start gap-y-5;
}

.whatsCardBidItem .item .itemContent {
  @apply flex flex-row lg:flex-col gap-y-5;
}

.whatsCardBidItem .item .itemContent .content {
  @apply flex flex-col gap-y-5;
}

.whatsCardBidItem .item .itemContent .content .heading {
  @apply flex p00 text-gray-900 font-bold hover:text-gray-700 dark:text-white dark:hover:text-[--light];
}

.whatsCardBidItem .item .itemContent .content .description {
  @apply dark:text-[--light];
}

.savedSeachesList {
  @apply border-gray-200 border-opacity-60 rounded-sm overflow-hidden;
}

.notificaton-modal,
.notificaton-modal-2 {
  @apply absolute border w-[200px] sm:w-[300px] lg:w-[400px] right-10 shadow-md rounded bg-white transition-all dark:bg-black dark:border-[--gray];
}

.notificaton-modal {
  @apply top-[72px];
}

.notificaton-modal-2 {
  @apply top-[50px];
}

.notify-header {
  @apply flex justify-between items-center px-3 py-3 gap-0.5 sm:gap-2 font-bold border-b-2 dark:text-white dark:border-[--gray];
}

.notify-header .notify-title {
  @apply flex gap-2;
}

.notify-header .notify-setting {
  @apply cursor-pointer;
}

.notify-body {
  @apply overflow-y-auto max-h-[300px];
}

.notify-body .main-content {
  @apply p-2;
}

.notify-body .main-content .content-item {
  @apply flex justify-start align-top items-start gap-2 px-1 py-1 opacity-60 border-b-2 dark:border-[--gray];
}

.notify-body .main-content .content-item:last-child {
  @apply border-none;
}

.notify-body .main-content .content-item.active {
  @apply opacity-100;
}

.notify-body .main-content .content-item .badge {
  @apply bg-[var(--gray-color)] text-white rounded-full p-1;
}

.notify-body .main-content .content-item.active .badge {
  @apply bg-[var(--main-color)];
}

.notify-body .main-content .content-item .item-desc {
  @apply flex flex-col justify-center align-top items-start leading-tight dark:text-white;
}

.notify-body .main-content .content-item .item-desc .item-title {
  @apply dark:text-white;
}

.notify-body .main-content .content-item .item-desc .item-ago {
  @apply p-0 text-sm opacity-70 dark:text-[--light];
}

.myListing:last-child {
  @apply border-none;
}

.toggleBtn,
.menuToggleBtn {
  @apply appearance-none transition-colors cursor-pointer h-9 rounded-[--butn-border-radius] bg-[var(--mid-gray-color)] dark:bg-[--gray] border-none;
}

.toggleBtn {
  @apply w-20;
}

.menuToggleBtn {
  @apply w-full xl:w-20;
}

.onOffRing {
  @apply w-6 h-6 absolute transform transition-transform bg-white rounded-[--butn-border-radius];
}

.onOffText {
  @apply absolute font-medium text-xs uppercase tracking-wider;
}

.swal2-popup {
  @apply dark:bg-[--dark-light];
}

.swal2-html-container,
.swal2-content {
  @apply: dark:text-white;
}

.requireField {
  @apply text-[var(--main-color)] text-base;
}

.applicationProcess {
  @apply flex items-center px-3 py-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 min-w-max dark:hover:bg-[--gray];
}

.fb-btn {
  @apply bg-transparent drop-shadow mytextShadow;
}

.auctionSlider {
  @apply border w-full;
}

.bucket {
  @apply 2xl:px-20 xl:px-16 lg:px-14 md:px-12 sm:px-8 px-4;
}

.car-show-case-swiper .swiper-wrapper {
  width: 100% !important;
  margin: 0 !important;
}

.noContentStyle {
  @apply text-center w-full text-lg font-medium drop-shadow-md py-12 tracking-wide;
}

.heading2 {
  @apply font-bold md:text-3xl text-2xl;
}

.swiperWfull.swiper-slide {
  width: 100% !important;
  margin: 0 !important;
}

.auctionSlider.swiper-slide {
  width: 100% !important;
  @apply mx-3;
}

.auctionSlider {
  @apply shadow-lg rounded;
}

.applicationProcess.active {
  @apply bg-[var(--mid-gray-color)] dark:bg-[--gray];
}

.jsCenter > .MuiTabs-flexContainer,
.jsCenter > .css-heg063-MuiTabs-flexContainer,
.css-heg063-MuiTabs-flexContainer {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.auction-card .displayONHover {
  @apply hidden;
}

.auction-card:hover .displayONHover {
  @apply block;
}

.carousel .slide .legend.bgTransparent {
  @apply bg-transparent;
}

.adminDashboardSwiper .carousel,
.reviewCarousel .carousel {
  @apply rounded-[--butn-border-radius] h-auto;
}

.adminDashboardSwiper .carousel .control-dots {
  @apply top-0 text-center m-0;
}

.reviewCarousel .carousel .control-dots {
  @apply bottom-0 text-center m-0;
}

.sliderOverlay {
  @apply absolute top-0 left-0 z-10 w-full h-full bg-blend-overlay bg-light;
}

.dotShadow {
  -webkit-box-shadow:
    1px 1px 2px var(--light-color) inset,
    1px 1px 2px var(--light-color);
  box-shadow:
    1px 1px 2px var(--light-color) inset,
    1px 1px 2px var(--light-color);
}

.DashboardCard {
  @apply rounded-md shadow px-6 py-4 flex flex-col sm:flex-row justify-center sm:justify-between sm:items-center gap-6 shadow2 min-h-full dark:border dark:border-[--gray] darkShadow;
}

.dashboardChartCard {
  @apply rounded border shadow2 dark:border dark:border-[--gray] darkShadow;
}

:is(.dark .apexcharts-text),
:is(.dark .apexcharts-legend-text),
:is(.dark .apexcharts-toolbar div svg),
:is(.dark .apexcharts-menu-item) {
  color: white !important;
  fill: white !important;
}

:is(.dark .apexcharts-menu-item:hover) {
  /* color: var(--dark) !important; */
  background: var(--gray) !important;
}

:is(.dark .apexcharts-menu-open) {
  background: var(--dark) !important;
  border: var(--gray) !important;
  @apply shadow-customDark;
}

:is(.dark .apexcharts-toolbar .apexcharts-pan-icon svg) {
  stroke: white !important;
}

.sidebarButnStyle .iconDiv {
  @apply dark:text-white;
}

.dashboardCards {
  @apply flex flex-wrap justify-center items-stretch lg:justify-start xl:justify-start 2xl:justify-start py-4 w-full h-full gap-3;
}

.dashboardCardItem {
  @apply w-full xl:w-1/2 2xl:w-[30%] py-2 mx-auto xl:m-0 grow;
}

.adminDashboardSwiper .carousel .control-dots .dot,
.reviewCarousel .carousel .control-dots .dot {
  @apply bg-[var(--main-color)] opacity-60 shadow-none;
  margin: 0 2px;
}

.adminDashboardSwiper .carousel .carsouelContent {
  @apply absolute bottom-1.5 left-2.5 text-left text-white cursor-pointer z-[10000];
}

.adminDashboardSwiper .carousel .heading {
  @apply font-extrabold p0 z-50 text-white;
}

.adminDashboardSwiper .carousel .paragrah {
  @apply p1;
}

.adminDashboardSwiper .carousel .control-dots .dot.selected,
.reviewCarousel .carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  @apply bg-[var(--main-color)] opacity-100;
}

.MuiAutocomplete-option:focus-visible,
.MuiAutocomplete-option.Mui-focusVisible {
  outline: none !important;
  background-color: var(
    --variant-plainActiveBg,
    var(
      --joy-palette-neutral-plainActiveBg,
      var(--joy-palette-neutral-200, #dde7ee)
    )
  );
}

/* tailwind special classes ends here */

.menu-active {
  @apply font-bold;
}

.showcase-images .showcase-item .showMore-text,
.list-showcase-images .showcase-item .showMore-text {
  @apply hidden;
}

.showcase-images .showcase-item:last-child .showMore-text,
.list-showcase-images .showcase-item:last-child .showMore-text {
  @apply block;
}

.containerSpacing {
  @apply bucket xl:px-6;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.profileImagePreview .d-onHover {
  @apply hidden;
}

.profileImagePreview:hover .d-onHover {
  @apply flex;
}

.scrollHiden::-webkit-scrollbar {
  @apply hidden;
}

.filterStyle {
  @apply flex text-sm overflow-hidden cursor-pointer hover:font-semibold opacity-100 hover:opacity-100 underline-offset-4 decoration-2 hover:underline;
}

.filterStyle.active {
  @apply font-semibold opacity-100 underline;
}

.filterStyleSpace {
  @apply hidden group-hover:block absolute bottom-0 left-0 w-full h-1 bg-[var(--main-color)] transition-all duration-200 ease-out;
}

.showCaseViewTabs {
  background: transparent !important;
  @apply h-[64px] px-[10px] align-middle flex justify-center items-center max-w-[80vw];
}

.tabsIncatorColor > button {
  @apply text-white;
}

.tabsIncatorColor > button:focus-visible {
  @apply outline-none;
}

.MuiTabList-root .Mui-selected {
  background-color: transparent !important;
}

.MuiTab-root {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.css-8koa9a-JoyTabs-root {
  @apply overflow-hidden;
}

.css-1ff3uza-JoyTabList-root {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  @apply min-w-fit;
}

.mytextShadow {
  text-shadow: inset 2px 2px 3px rgba(255, 255, 255, 0.5);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  @apply bg-clip-text;
}

.tabsIncatorColor > button.Joy-selected,
.tabsIncatorColor > button.Mui-selected,
.tabsIncatorColor > button:hover,
.tabsIncatorColor .Mui-selected,
.tabsIncatorColor > button:hover {
  background-color: transparent !important;
  -webkit-box-shadow: none;
  @apply shadow-none text-white;
}

ul.control-dots {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  @apply cursor-pointer h-fit z-[99999999];
}

.qaSwiper .swiper-button-prev,
.qaSwiper .swiper-button-next {
  @apply dark:bg-[--dark] dark:border-[--gray];
}

.car-show-case-swiper .swiper-button-prev,
.car-show-case-swiper .swiper-button-next {
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  @apply bg-transparent transition-all duration-500 ease-in-out;
}

.car-show-case-swiper .swiper-button-prev:hover,
.car-show-case-swiper .swiper-button-next:hover {
  background-color: rgba(0, 0, 0, 0.5);
  @apply rounded;
}

.car-show-case-swiper .swiper-button-prev::after,
.car-show-case-swiper .swiper-button-next::after {
  @apply text-lg;
}

.support .section,
.shipping .section {
  @apply py-4;
}

.support .section h2,
.shipping .section h2 {
  @apply py-3 font-bold;
}

.support .section h2,
.shipping .section h2,
.support .section h3,
.shipping .section h3 {
  @apply p0;
}

.support .section h3,
.shipping .section h3 {
  @apply font-medium;
}

.support .section p,
.shipping .section p {
  @apply leading-6;
}

.support .section .link {
  @apply py-6;
}

.support .section a,
.shipping .section a {
  @apply text-[var(--main-color)] cursor-pointer no-underline;
}

.shipping .section ul li,
.support .section a,
.support .section p,
.shipping .section p {
  @apply p1;
}

.fadedStyle {
  @apply mt-1;
}

.fadedStyle .ql-toolbar,
.fadedStyle .ql-container {
  /* Horizontal offset, vertical offset, blur radius, color */
  transition: box-shadow 0.3s ease !important;
  /* Add transition for smooth effect */
  @apply border-[var(--mid-gray-color)] outline-none;
}

.fadedStyle .ql-snow .ql-editor {
  @apply p1 tracking-normal leading-4;
}

.fadedStyle .ql-snow .ql-editor h1,
.fadedStyle .ql-snow .ql-editor h2,
.fadedStyle .ql-snow .ql-editor h3,
.fadedStyle .ql-snow .ql-editor h4,
.fadedStyle .ql-snow .ql-editor h5,
.fadedStyle .ql-snow .ql-editor h6 {
  @apply p0 tracking-wide leading-5 sm:leading-6 font-medium;
}

.fadedStyle .ql-toolbar {
  @apply rounded-tl-[--input-radius] rounded-tr-[--input-radius] bg-[--input-bg-faded];
}

.fadedStyle .ql-container {
  @apply bg-white rounded-bl-[--input-radius] rounded-br-[--input-radius];
}

.fadedStyle:focus-within .ql-toolbar,
.fadedStyle:focus-within .ql-container {
  @apply border-[--main-color] border-2;
}

.fadedStyle.error:focus-within .ql-toolbar,
.fadedStyle.error:focus-within .ql-container,
.fadedStyle.error .ql-toolbar,
.fadedStyle.error .ql-container {
  @apply border-[--error-color] border-2;
}

.fadedStyle .ql-toolbar button:hover,
.fadedStyle .ql-toolbar button:hover,
.fadedStyle .ql-toolbar button:focus,
.fadedStyle .ql-toolbar button:focus,
.fadedStyle .ql-toolbar button.ql-active,
.fadedStyle .ql-toolbar button.ql-active,
.fadedStyle .ql-toolbar .ql-picker-label:hover,
.fadedStyle .ql-toolbar .ql-picker-label:hover,
.fadedStyle .ql-toolbar .ql-picker-label.ql-active,
.fadedStyle .ql-toolbar .ql-picker-label.ql-active,
.fadedStyle .ql-toolbar .ql-picker-item:hover,
.fadedStyle .ql-toolbar .ql-picker-item:hover,
.fadedStyle .ql-toolbar .ql-picker-item.ql-selected,
.fadedStyle .ql-toolbar .ql-picker-item.ql-selected {
  color: var(--main-color) !important;
}

.fadedStyle .ql-snow.ql-toolbar button:hover .ql-stroke,
.fadedStyle .ql-snow .ql-toolbar button:hover .ql-stroke,
.fadedStyle .ql-snow.ql-toolbar button:focus .ql-stroke,
.fadedStyle .ql-snow .ql-toolbar button:focus .ql-stroke,
.fadedStyle .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.fadedStyle .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.fadedStyle .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.fadedStyle .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.fadedStyle .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.fadedStyle .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.fadedStyle .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.fadedStyle .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.fadedStyle .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.fadedStyle .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.fadedStyle .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.fadedStyle .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.fadedStyle .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.fadedStyle .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.fadedStyle .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.fadedStyle .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.fadedStyle .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.fadedStyle .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.fadedStyle .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.fadedStyle .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.fadedStyle .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.fadedStyle .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.fadedStyle .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.fadedStyle .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: var(--main-color) !important;
  color: var(--main-color) !important;
}

/* Mui popover */
.popover {
  @apply w-4 h-4 dark:text-white dark:fill-white;
}

.MuiPopoverMenu {
  @apply rounded shadow2 top-[25px] right-[40px] bg-white p-1.5 z-50 flex flex-col gap-1 justify-center items-center dark:bg-[--dark] dark:border dark:border-[--gray];
}

/* headless UI popover */

.headlessUIButton {
  @apply inline-flex items-center rounded-md px-3 py-2 text-base font-medium text-[--dark-text-color] hover:text-[--main-color] dark:text-white dark:hover:text-[--main-color] darkShadow;
}

.headlessUIButton.active {
  @apply text-[--main-color] dark:text-[--main-color];
}

.headlessUiPopoverPanel {
  @apply absolute right-[0] z-10 mt-3 w-fit transform;
}

.headlessUiPopoverPanelDiv {
  @apply overflow-hidden rounded-lg shadow-lg ring-1 bg-white ring-black/5 drop-shadow darkShadow dark:ring-[--gray] dark:bg-[--dark];
}

.filterSortMainTitle {
  @apply p00 font-bold text-center capitalize pb-2 dark:text-white;
}

.headlessUiPopSortLabel {
  @apply pb-1;
}

.headlessUiPopoverTitle2 {
  @apply p1 block font-bold tracking-wider dark:text-white;
}

@media screen and (max-width: 320px) {
  .xs-hidden {
    @apply hidden;
  }

  .xs-justify-center {
    @apply justify-center;
  }
}

@media screen and (max-width: 640px) {
  .sxleft-0 {
    @apply left-0;
  }

  .headerTitle {
    @apply p00;
  }

  .cardDetailsLabelStyle p {
    @apply p1;
  }

  .cardDetailsLabelStyle span {
    @apply p3;
  }

  .cardDetailsLabelStyle p,
  .cardDetailsLabelStyle span {
    @apply text-black dark:text-black;
  }

  .cardDetailsLabelStyle.warZone p,
  .cardDetailsLabelStyle.warZone span {
    @apply text-white dark:text-white;
  }
}

@media screen and (min-width: 1536px) {
  .two-xl-larger-text {
    font-size: 1.15rem !important;
  }

  /* .two-xl-large-text {
    font-size: 1rem !important;
  } */
}

input.toggleBtn:checked,
input.menuToggleBtn:checked {
  background-color: var(--main-color) !important;
}

.toggleBtn[type="checkbox"]:checked,
.menuToggleBtn[type="checkbox"]:checked {
  @apply bg-none;
}

input.toggleBtn:checked ~ span:last-child,
input.menuToggleBtn:checked ~ span:last-child {
  --tw-translate-x: 2rem;
}

.profileField {
  @apply py-1 pb-3 flex flex-col gap-3 w-full;
}

.profileField.avatar {
  @apply sm:flex-row justify-start items-center;
}

/* pre loader styling */
.preLoaderStyling,
.preLoaderStyling2 {
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  @apply flex justify-center items-center py-5 px-2.5;
}

.preLoaderStyling2 {
  background-color: rgba(0 0 0 / 0.45);
  -webkit-tap-highlight-color: transparent;
  -webkit-backdrop-filter: blur(4px);
  inset: 0px;
  @apply w-screen h-screen z-[99999999999999] fixed top-0 left-0;
}

.theme-text-color {
  color: var(--main-color) !important;
}

/* 
Questions Styling
*/
.swiper-slide.qSwiperItem {
  width: 360px !important;
  height: 230px !important;
  margin: 16px 12px !important;
  cursor: -webkit-grab;
  @apply cursor-grab overflow-hidden items-start pt-3;
}

.top-shadow-swiper {
  -webkit-box-shadow: 0 -5px 5px -35px rgba(229, 231, 235, 0.9);
  box-shadow: 0 -5px 5px -35px rgba(229, 231, 235, 0.9);
}

.swipper-bottom-bg {
  background-color: rgba(255, 255, 255, 0.9);
}

.cookie-banner {
  @apply bg-[--main-color] fixed left-0 bottom-0 text-white flex p1 justify-start md:justify-between items-start md:items-center px-2 sm:px-4 md:px-6 py-2 gap-0.5 md:gap-4 w-screen shadow z-[10000000];
}

.cookie-banner .cookie-content {
  @apply flex-1 text-center;
}

@media only screen and (max-width: 640px) {
  .swiper-slide.qSwiperItem {
    width: 300px !important;
    height: 300px !important;
    margin: 8px !important;
  }
}

.get-Quote-tBg {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 153, 0, 0.2)),
    to(rgba(255, 153, 0, 0.3))
  );
  background: -o-linear-gradient(
    rgba(255, 153, 0, 0.2),
    rgba(255, 153, 0, 0.3)
  );
  background: linear-gradient(rgba(255, 153, 0, 0.2), rgba(255, 153, 0, 0.3));
  @apply dark:bg-gradient-to-b dark:from-[--gray] dark:to-[--gray] dark:border dark:border-[--gray] darkShadow;
}

.butn-grad-Bg {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 153, 0, 0.05)),
    to(rgba(255, 153, 0, 0.1))
  );
  background: -o-linear-gradient(
    rgba(255, 153, 0, 0.05),
    rgba(255, 153, 0, 0.1)
  );
  background: linear-gradient(rgba(255, 153, 0, 0.05), rgba(255, 153, 0, 0.1));
  @apply dark:bg-gradient-to-b dark:from-[--gray] dark:to-[--gray] dark:border dark:border-[--gray] darkShadow;
}

/* 
  comment styling
*/
textarea.textAreaResizeStyling {
  @apply resize-none overflow-hidden min-h-[20px] max-h-[100px];
}

.iconbutnStyleOnDisable:disabled {
  @apply opacity-70 bg-[#eeeef0];
}

img {
  @apply max-w-none;
}

.quill {
  @apply p1 tracking-wide;
}

.quill ul li,
.quill ul {
  @apply list-disc;
}

.quill ol,
.quill ul,
.quill menu {
  /* padding-left: revert; */
  @apply m-auto ps-[revert];
}

.quill ol {
  @apply list-decimal;
}

:is(.dark .auction_details .quill p),
:is(.dark .auction_details .quill span) {
  color: var(--light) !important;
}

:is(.dark .auction_details .quill h1),
:is(.dark .auction_details .quill h2),
:is(.dark .auction_details .quill h3),
:is(.dark .auction_details .quill h4),
:is(.dark .auction_details .quill h5),
:is(.dark .auction_details .quill h6) {
  color: white !important;
}

[type="datetime-local"]:focus {
  box-shadow: none !important;
}

.quill h1,
.quill h2,
.quill h3,
.quill h4,
.quill h5,
.quill h6 {
  @apply p00 font-medium leading-8 py-2;
}

.ql-editor {
  @apply min-h-[100px] max-h-screen;
}

.carFax {
  @apply w-full p-1 md:p-2 rounded-[--butn-border-radius] bg-[--light-gray-color] flex justify-start items-center gap-1 cursor-pointer shadow-none;
}

.carFax div p {
  @apply dark:text-black;
}

.auctionBidInfo {
  @apply border rounded shadow px-2 sm:px-4 py-2 darkShadow dark:border-[--gray];
}

.cellTextCenter {
  @apply flex justify-start align-middle items-center;
}

.sortStyle {
  @apply flex flex-col md:flex-row gap-2 flex-wrap;
}

.filterStyle {
  @apply p1 dark:text-white;
}

.InputFieldSetting [type="text"]:focus,
.InputFieldSetting [type="email"]:focus,
.InputFieldSetting [type="url"]:focus,
.InputFieldSetting [type="password"]:focus,
.InputFieldSetting [type="number"]:focus,
.InputFieldSetting [type="date"]:focus,
.InputFieldSetting [type="datetime-local"]:focus,
.InputFieldSetting [type="month"]:focus,
.InputFieldSetting [type="search"]:focus,
.InputFieldSetting [type="tel"]:focus,
.InputFieldSetting [type="time"]:focus,
.InputFieldSetting [type="week"]:focus,
.InputFieldSetting [multiple]:focus,
.InputFieldSetting textarea:focus,
.InputFieldSetting select:focus,
.muiInput [type="text"]:focus,
.muiInput [type="email"]:focus,
.muiInput [type="url"]:focus,
.muiInput [type="password"]:focus,
.muiInput [type="number"]:focus,
.muiInput [type="date"]:focus,
.muiInput [type="datetime-local"]:focus,
.muiInput [type="month"]:focus,
.muiInput [type="search"]:focus,
.muiInput [type="tel"]:focus,
.muiInput [type="time"]:focus,
.muiInput [type="week"]:focus,
.muiInput [multiple]:focus,
.muiInput textarea:focus,
.muiInput select:focus {
  box-shadow: none !important;
}

.cardh {
  @apply h-[360px] sm:h-[300px] md:h-[236px] lg:h-[310px] xl:h-[224px] 2xl:h-[300px] cursor-pointer w-full object-cover;
}

.whyAutoBidsItem {
  @apply flex flex-col items-center;
}

.whyAutoBidsItem .stats {
  @apply items-center head3 font-bold text-gray-900 tracking-widest;
}

.whyAutoBidsItem .label {
  @apply p1 text-gray-900 tracking-widest my-3;
}

.siteLogo {
  @apply h-auto w-full max-w-[240px] sm:max-w-[300px];
}

.sico {
  @apply w-6;
}

@media only screen and (min-width: 901px) and (max-width: 940px) {
  .cardh {
    @apply h-[220px];
  }
}

@media only screen and (min-width: 818px) and (max-width: 900px) {
  .cardh {
    @apply h-[200px];
  }
}

@media only screen and (min-width: 768px) and (max-width: 818px) {
  .cardh {
    @apply h-[180px];
  }
}

@media only screen and (min-width: 529px) and (max-width: 598px) {
  .cardh {
    @apply h-[320px];
  }
}

@media only screen and (min-width: 475px) and (max-width: 530px) {
  .cardh {
    @apply h-[250px];
  }
}

@media only screen and (min-width: 425px) and (max-width: 474px) {
  .cardh {
    @apply h-[220px];
  }
}

@media only screen and (min-width: 375px) and (max-width: 424px) {
  .cardh {
    @apply h-[180px];
  }
}

@media only screen and (max-width: 374px) {
  .cardh {
    @apply h-[150px];
  }
}

@media only screen and (min-width: 768px) and (max-width: 834px) {
  .cookie-banner {
    @apply p1;
  }
}

@media only screen and (max-width: 425px) {
  .cookie-banner {
    @apply p2;
  }
}

@media only screen and (max-width: 768px) {
  .css-8koa9a-JoyTabs-root {
    @apply overflow-x-auto overflow-y-hidden;
  }
}

@media only screen and (max-width: 375px) {
  .sm-text-small {
    @apply text-sm;
  }

  .sm-text-lg {
    @apply text-lg;
  }

  .sm-w-full {
    @apply w-full;
  }
}

.main-text-color {
  @apply text-[var(--main-color)];
}

.main-border-color {
  @apply border-[var(--main-color)];
}

.main-bg-color {
  @apply bg-[var(--main-color)];
}

.main-outline-button-rounded {
  @apply p1 inline-block rounded border-2 border-gray-200 dark:border-[--gray] lg:px-5 px-3 py-1 font-semibold leading-normal transition duration-150 ease-in-out hover:border-[--main-color] hover:bg-[--main-color] hover:text-white focus:outline-none motion-reduce:transition-none w-full md:w-fit dark:text-white;
}

.main-outline-button-rounded.active {
  @apply bg-[--main-color] text-white border-none;
}

.main-outline-button-rounded.deactive {
  @apply text-black dark:text-[--light] hover:text-white;
}

.clicableLink {
  @apply text-[--main-color] dark:text-[--main-color];
}

.notClicableLink {
  @apply text-black dark:text-white;
}

.main-outline-button {
  @apply p1 inline-block rounded-md border-2 border-[var(--main-color)] px-3 py-1 leading-normal transition duration-150 ease-in-out hover:border-[var(--main-color)] hover:bg-[var(--main-color)] hover:text-white focus:outline-none motion-reduce:transition-none;
}

.main-active-button {
  @apply bg-[var(--main-color)] text-white;
}

/* ribbon */
.ribbon {
  @apply w-[136px] h-[160px] overflow-hidden absolute;
}

.ribbon::before,
.ribbon::after {
  @apply absolute -z-[1] block content-[""];
}

.ribbon::before {
  @apply border-8 border-[var(--light-color)];
}

.ribbon span {
  text-shadow: 0.1px 0.1px var(--light-color);
  @apply bg-black text-[var(--light-color)] text-sm font-medium leading-none tracking-wider whitespace-nowrap text-center w-[256px] absolute block py-1.5 capitalize drop-shadow-sm;
}

/* top left*/
.ribbon-top-left {
  @apply top-[-8px] left-[-4px];
}

.ribbon.ribbon-top-left span {
  @apply pr-1.5 ps-0;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
  @apply border-t-transparent border-s-transparent;
}

.ribbon-top-left::before {
  @apply top-0 right-0;
}

.ribbon-top-left::after {
  @apply bottom-0 left-0;
}

.ribbon-top-left span {
  @apply right-[-56px] top-[26px] rotate-[-45deg];
}

/* top right*/
.ribbon-top-right {
  @apply top-[-16px] right-0;
}

.ribbon.ribbon-top-right span {
  @apply ps-1.5 pe-0;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  @apply border-t-transparent border-e-transparent;
}

.ribbon-top-right::before {
  @apply top-0 left-[20px] md:left-[5px];
}

.ribbon-top-right::after {
  @apply bottom-0 right-0;
}

.ribbon-top-right span {
  @apply left-[-48px] top-[28px] rotate-45;
}

/* bottom left*/
.ribbon-bottom-left {
  @apply bottom-[-8px] left-[-4px];
}

.ribbon.bottom-top-left span {
  @apply ps-1.5 pe-0;
}

.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
  @apply border-b-transparent border-s-transparent;
}

.ribbon-bottom-left::before {
  @apply bottom-0 right-0;
}

.ribbon-bottom-left::after {
  @apply top-0 left-0;
}

.ribbon-bottom-left span {
  @apply right-[-56px] bottom-[26px] rotate-[225deg];
}

/* bottom right*/
.ribbon-bottom-right {
  @apply bottom-[-8px] right-[-4px];
}

.ribbon.bottom-bottom-right span {
  @apply ps-1.5 pe-0;
}

.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  @apply border-b-transparent border-e-transparent;
}

.ribbon-bottom-right::before {
  @apply bottom-0 left-0;
}

.ribbon-bottom-right::after {
  @apply top-0 right-0;
}

.ribbon-bottom-right span {
  @apply left-[-56px] bottom-[26px] rotate-[-225deg];
}

/* New: IOS will still zoom, unless you use 16px on the input without the focus. */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus,
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  select:focus,
  textarea:focus,
  input:focus,
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}

@media only screen and (min-width: 1536px) {
  .bid-fs {
    @apply p1;
  }
}

@media only screen and (max-width: 1023px) {
  .cardDetailsLabelStyle p,
  .cardDetailsLabelStyle span {
    line-height: 1;
  }
}

@media only screen and (max-width: 396px) {
  .xs-bottom-14 {
    @apply bottom-16;
  }
}

@media only screen and (max-width: 366px) {
  .xs-text-base {
    @apply text-base;
  }

  .xs-h-6 {
    height: 1.5rem !important;
  }

  .xs-h-12 {
    @apply h-10;
  }

  .xs-small-col {
    @apply flex-col justify-center items-stretch;
  }

  .xs-small-col h3 {
    @apply text-center;
  }
}

@media only screen and (max-width: 360px) {
  .sx-center {
    @apply justify-center items-center;
  }
}

@media only screen and (max-width: 342px) {
  .xs-hidden {
    @apply hidden;
  }

  .xs-h-6 {
    height: 2.5rem !important;
  }
}

/* all inputs font size style start*/
select:focus,
textarea:focus,
input:focus,
select,
textarea,
input {
  font-size: 1rem !important;
}

/* all inputs font size style end */
